import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const { desktopLarge, tablet, desktopMax, mobilelgMax } = breakpoints;

function isSelectedStyle({ $isSelected = false }) {
  if ($isSelected) {
    return css`
      transform: scale(1.05);
      border: blue 4px solid;
      border-radius: 0.5rem;
    `;
  }
}

function isDisabledStyle({ $isDisabled = false }) {
  if ($isDisabled) {
    return css`
      opacity: 0.5;
      pointer-events: none;
    `;
  }
}

type Props = {
  $isSelected: boolean;
  $isDisabled: boolean;
};

export default styled.div<Props>`
  background: var(--colors-primary-3);
  box-shadow: 0px 0px 26px rgba(124, 146, 213, 0.3);
  margin: 1rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover {
    transform: scale(1.025);
    transition: all 0.2s ease-in-out;
  }

  @media ${desktopLarge} {
    width: calc(28% - 2rem);
  }
  @media ${tablet + ` and ` + desktopMax} {
    width: calc(41% - 6rem);
  }
  @media ${mobilelgMax} {
    max-width: 100%;
  }

  .card-package-body-container {
    position: relative;
    background: var(--colors-primary-3);
    cursor: pointer;
    user-select: none;
    flex-grow: 1;
    ${isSelectedStyle}

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 110px;
      z-index: 3;
    }

    pre {
      font-size: 0.75em;
      background: #eee;
      padding: 0.5rem;
      white-space: normal;
    }
    button {
      font-size: 1.1rem;
    }
  }

  &:nth-child(3) {
    background: transparent linear-gradient(180deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
  }

  ${isDisabledStyle}
`;
