import StyledCheckoutCountdown from '@components/Organisms/OrderForms/styled/StyledCheckoutCountdown';
import useCheckoutCountdownContext from '@hooks/useCheckoutCountdownContext';
import { getDiff, getDuration, isRunning } from '@lib/utils/checkoutCountdown';
import Image from 'next/image';
import { FC, useEffect, useMemo, useState } from 'react';

export const CheckoutCountdown: FC<{ className?: string }> = ({ className }) => {
  const { runsOutAt, isOvertime } = useCheckoutCountdownContext();
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    if (!isRunning(runsOutAt) || !runsOutAt) return;

    const interval = setInterval(() => {
      const { minutes, seconds } = getDiff(runsOutAt);
      if (minutes < 0 || seconds < 0) {
        setMin(0);
        setSec(0);
        clearInterval(interval);
        return;
      }
      setMin(minutes);
      setSec(seconds);
    }, 1000);

    return () => clearInterval(interval);
  }, [runsOutAt]);

  const overtimeDuration = useMemo(() => getDuration({ isOvertime: true }), []);

  if (!isRunning(runsOutAt) || !runsOutAt) {
    return null;
  }

  return (
    <div className={className}>
      {isOvertime ? (
        <p className="text-xl mb-4" style={{ maxWidth: 500 }}>
          <strong>
            Oops! Your session timed out but don&apos;t worry, we&apos;ve given you{' '}
            {overtimeDuration} extra minutes to complete your order. Lets get you connected!
          </strong>
        </p>
      ) : null}
      <StyledCheckoutCountdown>
        <Image src="/icons/Clock.png" alt="Time" width={40} height={40} />
        <div className="ml-2 flex flex-col md:flex-row">
          <span className="countdownTimer md:inline">
            {min.toString().padStart(2, '0')}:{sec.toString().padStart(2, '0')} minutes
          </span>
          <span className="countdownLabel">to complete the checkout process</span>
        </div>
      </StyledCheckoutCountdown>
    </div>
  );
};
