import React, { useEffect } from 'react';
import Link from 'next/link';
import Media from 'react-media';

import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

import { Button, Icon } from '@components/Atoms';
import { ButtonRow } from '@components/Molecules/ButtonRow/ButtonRow';
import { ButtonType } from '@components/Atoms/Button/types';

import { MiniBasketContents } from './MiniBasketContents';

import { breakpoints } from '@theme/breakpoints';
import StyledMiniBasket from './styled/StyledMiniBasket';
import StyledMiniBasketPopup from './styled/StyledMiniBasketPopup';
import StyledMiniBasketStatic from './styled/StyledMiniBasketStatic';
import StyledMiniBasketBackdrop from './styled/StyledMiniBasketBackdrop';
import StyledMiniBasketCloseBar from './styled/StyledMiniBasketCloseBar';

import ArrowUpIcon from '@public/icons/Arrow-Up.svg';
import BasketIcon from '@public/icons/Basket-Blue.svg';
import { useRouter } from 'next/router';
import { handleConditionalExecution } from '@lib/utils/conditionalExecution';

export const MiniBasket = ({
  miniBasketItem,
  showAddress,
  readOnly = false,
  fullWidth = false,
}: any) => {
  return (
    <StyledMiniBasket className="basket-contents">
      {miniBasketItem ? (
        <MiniBasketContents
          basketItem={miniBasketItem}
          showAddress={showAddress}
          readOnly={readOnly}
          fullWidth={fullWidth}
        />
      ) : (
        <div>Nothing in basket</div>
      )}
    </StyledMiniBasket>
  );
};

export const MiniBasketPopup = () => {
  const {
    basketItem,
    getBasketItemProduct,
    getAddonsProducts,
    promoCodes,
    isBasketPopupOpen,
    setIsBasketPopupOpen,
    bundle,
  } = useBasketContext();
  const miniBasketItem = getBasketItemProduct();
  const addonsProducts = getAddonsProducts();
  const { postcodeItem } = usePostcodeContext();

  useEffect(() => {
    document?.querySelector('html')?.classList[isBasketPopupOpen ? 'add' : 'remove']('no-scroll');
  }, [isBasketPopupOpen]);

  const router = useRouter();
  const { pathname } = router || {};

  const isCheckingOut = ['/service-available', '/extras'].indexOf(pathname) > -1;

  // Disable OTS check
  const trueCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/checkout';
  };

  const falseCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/ots';
  };

  const redirect = handleConditionalExecution(
    process.env.NEXT_PUBLIC_DISABLE_OTS === 'true',
    trueCallback,
    falseCallback,
  )();

  return (
    <>
      {isBasketPopupOpen && (
        <div>
          <StyledMiniBasketBackdrop onClick={() => setIsBasketPopupOpen(false)} />
          <StyledMiniBasketPopup>
            <StyledMiniBasketCloseBar onClick={() => setIsBasketPopupOpen(false)}>
              <div>
                <Icon xs icon={BasketIcon} />
              </div>
              <div>Your Basket</div>
              <div>
                <Icon xs icon={ArrowUpIcon} />
              </div>
            </StyledMiniBasketCloseBar>
            <MiniBasket
              miniBasketItem={miniBasketItem}
              addonsProducts={addonsProducts}
              promoCodes={promoCodes}
            />
            {basketItem && !isCheckingOut && (
              <div className="mt-6">
                <ButtonRow column>
                  <div>
                    <Link passHref href={bundle && postcodeItem ? '/checkout' : redirect}>
                      <Button buttonType={ButtonType.PRIMARY} fullWidth as="a" href="/checkout">
                        Continue to Checkout
                      </Button>
                    </Link>
                  </div>
                </ButtonRow>
              </div>
            )}
          </StyledMiniBasketPopup>
        </div>
      )}
    </>
  );
};

export const MiniBasketStatic = ({
  show = false,
  fullWidth = false,
  showAddress = true,
  readOnly = false,
  className = '',
}) => {
  const { getBasketItemProduct, getAddonsProducts, promoCodes } = useBasketContext();
  const miniBasketItem = getBasketItemProduct();
  const addonsProducts = getAddonsProducts();

  return (
    <>
      <Media queries={breakpoints}>
        {({ desktop }) =>
          (desktop || fullWidth) && (
            <StyledMiniBasketStatic
              className={`mini-basket ${className}`}
              $show={show}
              $fullWidth={fullWidth}
            >
              {desktop && (
                <h3 className="font-geomanist text-white md:text-[2rem] mb-4">Your selection</h3>
              )}
              <div>
                <MiniBasket
                  miniBasketItem={miniBasketItem}
                  addonsProducts={addonsProducts}
                  promoCodes={promoCodes}
                  showAddress={showAddress}
                  readOnly={readOnly}
                  fullWidth={fullWidth}
                />
              </div>
            </StyledMiniBasketStatic>
          )
        }
      </Media>
    </>
  );
};
