import { CheckoutCountdown } from '@components/Organisms/OrderForms/CheckoutCountdown';
import React, { useCallback, useState, FC, useEffect, useMemo } from 'react';

import { ButtonType } from '@components/Atoms/Button/types';

import { Button, CheckBox, Icon } from '@components/Atoms';
import { Dropdown } from '@components/Molecules';
import { CheckoutFormRow } from './CheckoutFormRow';

import { renderParagraphs } from '@lib/utils';

import { ContactDetailsFormT, ContactDetailsFormData } from './types';

import StyledButtonRow from './styled/StyledButtonRow';
import StyledCheckoutCheckboxContainer from './styled/StyledCheckoutCheckboxContainer';
import StyledCheckoutCheckbox from './styled/StyledCheckoutCheckbox';
import StyledCheckoutFormRow from './styled/StyledCheckoutFormRow';
import StyledIconParagraph from './styled/StyledIconParagraph';

import LockIcon from '@public/icons/Lock.svg';
import { Heading } from '@components/Atoms/Heading';
import useCheckoutContext from '@hooks/useCheckoutContext';
import { StyledErrorBar, StyledPreorderNote } from '@components/Atoms/Form';
import { CheckoutTooltip } from '@components/Atoms/CheckoutTooltip';
import usePostcodeContext from '@hooks/usePostcodeContext';
import useStickyCheckoutToggle from '@hooks/useStickyCheckoutToggle';
import useBasketContext from '@hooks/useBasketContext';
import Panel from '@components/Atoms/Panel/Panel';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const StyledAddresCheck = styled.div`
  margin-top: 2.313rem;

  & > * {
    margin-top: 1rem;
  }

  a {
    font-family: var(--font-geomanist);
    text-decoration: underline;
    color: var(--color-primary);
  }

  .address {
    p {
      margin-top: 0;
      font-size: 1.563rem;
    }
  }

  .highlighted-text {
    font-size: 1.313rem;
    font-family: var(--fonts-family-secondary);
    color: #000;
    @media ${breakpoints.tablet} {
      font-size: 1.563rem;
    }
  }
`;

const StyledAdditionalForm = styled.div`
  margin-top: 3.75rem;

  .form-info {
    margin-top: 1rem;
  }

  .checkbox-label {
    font-size: 0.875rem;
  }

  .stop {
    background: #fff2f2;
    border-radius: 10px;
    opacity: 1;

    &__content {
      padding: 2rem 2rem;

      & > * {
        margin-top: 1rem;
      }

      h4 {
        margin-top: 0;
      }
    }
  }

  div[class*='CheckBox'] {
    margin-left: 1.5rem;
  }
`;

enum AddressType {
  RES = 'RES',
  BUS = 'BUS',
}

export const ContactDetailsForm: FC<ContactDetailsFormT> = ({
  defaultFormData = {},
  action,
  onSubmit,
  registerInterest = false,
  smallCopy,
  additionalDetailsHeading,
  additionalDetailsSmallCopy,
  hasExtraFields = false,
  isContactDetailsErrored = false,
  secureShoppingTitle,
  secureShoppingDescription,
  showTooltip = false,
  recaptchaErrored = false,
  emergencyServicesQuestion,
  healthcareAlarmQuestion,
  retainLandlineQuestion,
  confirmResponsibilityStatement,
  portingInfoText,
  homePhoneProducts,
  otsProviders = [],
  heading,
}) => {
  const [formData, setFormData] = useState<ContactDetailsFormData>(defaultFormData);
  const { addressType } = usePostcodeContext();

  const isSignup = action === 'SIGNUP';
  const isPreorder = action === 'PREORDER';
  const isRegisterInterest = action === 'ROI';
  const isBusiness = addressType === AddressType.BUS;
  const { postcodeItem } = usePostcodeContext();

  const { setCheckoutField, customer, customerAdditional, setAdditionalCheckoutField } =
    useCheckoutContext();
  const { getAddonsProducts, setAddons } = useBasketContext();

  const extras = getAddonsProducts();
  const homePhoneProduct = extras?.find((extra) => extra?.name.includes('Home Phone'));

  const isEligible =
    !homePhoneProduct || (homePhoneProduct && customerAdditional?.hasAlternativeContactMethod);

  const rows = [
    {
      key: 'contact_name',
      label: 'First Name',
      required: true,
      value: '',
      autocomplete: 'given-name',
    },
    {
      key: 'contact_surname',
      label: 'Last Name',
      required: true,
      value: '',
      autocomplete: 'family-name',
    },
    { key: 'email', label: 'Email Address', required: true, value: '', autocomplete: 'email' },
    {
      key: 'confirm',
      label: 'Confirm Email Address',
      required: true,
      value: '',
      autocomplete: 'no',
    },
    {
      key: 'birthdate',
      label: 'Date of birth',
      placeholder: 'Date of birth',
      type: 'date',
      required: true,
      value: '',
      autocomplete: 'bday',
    },
    { key: 'phone', label: 'Phone Number', required: true, value: '', autocomplete: 'tel' },
    {
      key: 'company_name',
      label: 'Company Name',
      display: hasExtraFields && isBusiness,
      required: true,
      value: '',
    },
    {
      key: 'company_number',
      label: 'Company Number',
      display: hasExtraFields && isBusiness,
      required: true,
      value: '',
    },
    {
      key: 'building_number',
      label: 'Building Number',
      display: hasExtraFields,
      required: false,
      value: '',
    },
    {
      key: 'street_name',
      label: 'Street Name',
      display: hasExtraFields,
      required: true,
      value: '',
    },
    { key: 'town', label: 'Town', display: hasExtraFields, required: true, value: '' },
    { key: 'postcode', label: 'Postcode', display: hasExtraFields, required: true, value: '' },
    { key: 'accept_marketing', type: 'checkbox', required: true, value: '' },
    {
      key: 'accept_terms',
      type: 'checkbox',
      display: isRegisterInterest,
      required: isRegisterInterest,
      value: '',
    },
  ]
    .filter(({ display = true }) => display)
    .map((row) => {
      row.value = customer[row.key];
      return row;
    });

  const splitBirthDate = customer.birthdate.split('-');
  const validators = [
    {
      keys: [],
      isValid: rows.filter(({ required }) => required).every((row) => customer[row.key]),
      message: 'Field is required',
    },
    {
      keys: ['contact_title'],
      isValid:
        customer.contact_title &&
        ['DR', 'MISS', 'MRS', 'MR', 'MRS', 'MS', 'SIR'].indexOf(
          customer?.contact_title?.value || '',
        ) > -1,
      message: 'Please enter a valid title',
    },
    {
      keys: ['confirm'],
      isValid: customer.email && customer.confirm && customer.email === customer.confirm,
      message: 'Emails do not match',
    },
    {
      keys: ['email'],
      isValid: customer.email?.match(/^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/),
      message: 'Email is invalid',
    },
    {
      keys: ['phone'],
      isValid: customer.phone?.match(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      ),
      message: 'Phone is invalid',
    },
    {
      keys: ['origin'],
      isValid: Object.keys(customer.origin).length,
      message: 'Field is required',
    },
    {
      keys: ['birthdate'],
      isValid: customer.birthdate?.match(/^\d{4}-\d{2}-\d{2}$/) && +splitBirthDate[0] > 1900,
      message: 'Please enter a valid date',
    },
  ];

  let requiredAdditionalFields: string[] = [];

  if (homePhoneProduct) {
    requiredAdditionalFields = [...requiredAdditionalFields, 'wantsToRetainExistingLandline'];
  }

  const additionalFieldValidators = [
    {
      keys: [],
      isValid: requiredAdditionalFields.every((name) => customerAdditional[name] !== undefined),
      message: 'Field is required',
    },
  ];

  const landlineFieldValidator = [
    {
      keys: ['landlineNumber'],
      isValid: customerAdditional.landlineNumber?.match(
        /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/,
      ),
      message: 'Please enter a valid telephone number',
    },
  ];

  const providerFieldValidator = [
    {
      keys: ['currentProvider'],
      isValid: !!customerAdditional.currentProvider,
      message: 'Field is required',
    },
  ];

  const additionalDetailsValid = additionalFieldValidators.every(({ isValid }) => isValid);

  const areLandLineFieldsRequired =
    !!customerAdditional.wantsToRetainExistingLandline && !!homePhoneProduct;

  let landlineFieldsValid = true;

  if (areLandLineFieldsRequired) {
    landlineFieldsValid =
      Boolean(landlineFieldValidator.every(({ isValid }) => Boolean(isValid))) &&
      Boolean(providerFieldValidator.every(({ isValid }) => Boolean(isValid)));
  }

  const speedDropdownItems = [
    { label: 'Up to 24Mbps', value: 'Up to 24Mbps' },
    {
      label: 'More than 24Mbps but less than 100Mbps',
      value: 'More than 24Mbps but less than 100Mbps',
    },
    { label: '100Mbps or more', value: '100Mbps or more' },
  ];

  const howDidYouHearItems = [
    { value: 'GGLADS', label: 'Ad on Google' },
    { value: 'HREVENT', label: 'Attended an event' },
    { value: 'HRBILLPOST', label: 'Billboard/ poster' },
    { value: 'COMPARISONWEBSITES', label: 'Comparison Websites' },
    { value: 'HEAREMAIL', label: 'Email' },
    { value: 'HRFACE', label: 'Facebook ad' },
    { value: 'HRFRIEND', label: 'Friend/family recommended' },
    { value: 'RADIO', label: 'Heard us on the Radio' },
    { value: 'FSBKINSTA', label: 'Instagram' },
    { value: 'FLYER', label: 'Offer on flyer' },
    { value: 'OTHER', label: 'Other' },
    { value: 'REFERRAL', label: 'Referral' },
    { value: 'RESERARCH', label: 'Research' },
    { value: 'SALESPERSON', label: 'Salesperson' },
    { value: 'LCLNEWSPPR', label: 'Saw an advert in a local magazine' },
    { value: 'HRTIKTOK', label: 'TikTok' },
    { value: 'TV', label: 'TV' },
  ];

  const contactTitles = [
    { value: 'DR', label: 'Dr.' },
    { value: 'MISS', label: 'Miss.' },
    { value: 'MR', label: 'Mr.' },
    { value: 'MRS', label: 'Mrs.' },
    { value: 'MS', label: 'Ms.' },
    { value: 'SIR', label: 'Sir' },
  ];

  const providerItems = useMemo(
    () => otsProviders.map((p) => ({ value: p.rcpid, label: p.name })),
    [otsProviders],
  );

  const isValid = validators.every(({ isValid }) => isValid);

  const submitForm = useCallback(
    (event) => {
      event?.preventDefault();
      if (onSubmit) {
        onSubmit(customer);
      }
    },
    [customer, onSubmit],
  );

  const updateFormData = (key: string, value: any) => {
    setCheckoutField(key, value);
  };

  const updateAdditionalFormData = (key: string, value: any) => {
    if (
      [
        'hasAlternativeContactMethod',
        'hasHealthcareOrMedicalAlarm',
        'needsHelpSwitchingProvider',
        'wantsToRetainExistingLandline',
        'confirmResponsibility',
      ].indexOf(key) > -1
    ) {
      value = !!+value;
    }

    if (key === 'wantsToRetainExistingLandline' && homePhoneProduct?.id_product) {
      // removeAddon(homePhoneProducts?.id_product);

      setAddons((prev = []) => {
        let product: any = {
          id_product: homePhoneProduct?.id_product,
        };
        if (value) {
          product = homePhoneProducts?.find((product) => product?.name?.includes('existing'));
        } else {
          product = homePhoneProducts?.find((product) => !product?.name?.includes('existing'));
        }

        return [
          ...prev.filter((addonId) => {
            return !homePhoneProducts?.map((product) => product?.id_product).includes(addonId);
          }),
          product?.id_product,
        ];
      });
    }
    setAdditionalCheckoutField(key, value);
  };

  const updateSelectedSpeed = useCallback((state) => {
    if (!state.value) {
      return;
    }
    updateFormData('current_speed', state.value);
  }, []);

  useEffect(() => {
    if (defaultFormData && hasExtraFields) {
      setFormData(defaultFormData);
    }
  }, [defaultFormData, hasExtraFields]);

  const canProceed =
    isEligible &&
    additionalDetailsValid &&
    landlineFieldsValid &&
    customerAdditional.confirmResponsibility;

  useStickyCheckoutToggle(isValid && canProceed);

  return (
    <div className="container xl:max-w-[41rem]">
      {heading ? (
        <Heading level="2" className="my-12">
          {heading}
        </Heading>
      ) : null}

      {/* {isSignup && <div>Secure checkout</div>} */}
      {/* {isRegisterInterest && <div>Register your interest</div>} */}
      {showTooltip && <CheckoutTooltip />}

      <CheckoutCountdown className="mb-6" />

      {isContactDetailsErrored && (
        <StyledErrorBar>
          There was an error submitting your contact details. Please try again.
        </StyledErrorBar>
      )}

      {recaptchaErrored && (
        <StyledErrorBar>Recaptcha failed. Please refresh and try again</StyledErrorBar>
      )}

      <div className="bg-gray-100 p-3 rounded sm:mb-9 mb-2">
        <strong>Address</strong>
        <br />
        {postcodeItem?.address}
      </div>

      <p className="text-gray-500 text-sm mb-9 sm:hidden">
        This is the address you would like your Hey!Broadband installed and your billing address.
      </p>

      <form id="contact-form" onSubmit={submitForm}>
        {hasExtraFields && (
          <Dropdown
            items={speedDropdownItems}
            id="select-speed"
            placeholderLabel="Current Speed"
            onChange={updateSelectedSpeed}
          />
        )}
        <StyledCheckoutFormRow>
          <Dropdown
            items={contactTitles}
            id="contact_title"
            placeholderLabel="Title"
            defaultSelectedItem={customer.contact_title}
            onChange={(state: { value: string }) =>
              state?.value ? updateFormData('contact_title', state) : null
            }
            required={true}
          />
        </StyledCheckoutFormRow>
        {rows
          .filter(({ type }) => type !== 'checkbox')
          .map(({ key, label, type, required, autocomplete }) => (
            <CheckoutFormRow
              key={key}
              name={key}
              label={label}
              formData={customer}
              validators={validators}
              onChange={updateFormData}
              type={type}
              required={required}
              autoComplete={autocomplete}
            />
          ))}
        <StyledCheckoutFormRow>
          <Dropdown
            items={howDidYouHearItems}
            id="origin"
            placeholderLabel="How did you hear about us?"
            defaultSelectedItem={customer.origin}
            onChange={(state: { value: string }) =>
              state?.value ? updateFormData('origin', state) : null
            }
            required={true}
          />
        </StyledCheckoutFormRow>

        {!isRegisterInterest && (
          <StyledAdditionalForm>
            {homePhoneProduct && (
              <CheckoutFormRow
                key="wantsToRetainExistingLandline"
                name="wantsToRetainExistingLandline"
                renderLabel={() => (
                  <Heading level={3} withIcon>
                    <img
                      className="heading__icon"
                      src="/Phone_icon.svg"
                      style={{ width: '1.875rem', height: '1.875rem' }}
                      alt=""
                    />
                    {retainLandlineQuestion ||
                      'Would you like to keep your existing landline number?'}
                    *
                  </Heading>
                )}
                formData={customerAdditional}
                validators={additionalFieldValidators}
                onChange={updateAdditionalFormData}
                type="boolean"
                className="booleanInput smallBooleanInput"
                required={true}
              />
            )}
            {areLandLineFieldsRequired && (
              <>
                <div>
                  <CheckoutFormRow
                    key="landlineNumber"
                    name="landlineNumber"
                    label="Your landline number"
                    formData={customerAdditional}
                    validators={landlineFieldValidator}
                    onChange={updateAdditionalFormData}
                    required={true}
                  />
                  <StyledCheckoutFormRow>
                    <Dropdown
                      items={providerItems}
                      id="currentProvider"
                      placeholderLabel="My current landline provider is..."
                      defaultSelectedItem={customerAdditional.currentProvider}
                      onChange={(state: { value: string }) => {
                        if (state?.value) {
                          updateAdditionalFormData('currentProvider', state);
                        }
                      }}
                      required={true}
                    />
                  </StyledCheckoutFormRow>
                </div>
                <Panel>
                  <h4 className="heading--h4 text-primary">
                    Please read the following information
                  </h4>
                  {portingInfoText ? (
                    portingInfoText
                  ) : (
                    <>
                      <p>
                        Do not cancel phone with your current provider until we have confirmed a
                        port date or you will lose your number.
                      </p>
                      <p>
                        We will send your port request on the day your broadband is up and running,
                        and from then it will take 2-3 weeks for the port to be complete, again your
                        phone service with your previous provider needs to be active until your port
                        date.
                      </p>
                    </>
                  )}
                </Panel>
              </>
            )}
            {homePhoneProduct && (
              <>
                <CheckoutFormRow
                  key="hasAlternativeContactMethod"
                  name="hasAlternativeContactMethod"
                  renderLabel={() => (
                    <Heading level={3}>
                      {emergencyServicesQuestion ||
                        'Do you have another way to contact emergency services from this address, such as a mobile phone?'}
                      *
                    </Heading>
                  )}
                  formData={customerAdditional}
                  // validators={validators}
                  onChange={updateAdditionalFormData}
                  type="boolean"
                  className="booleanInput smallBooleanInput"
                  required={true}
                />
                {!isEligible && (
                  <div className="stop">
                    <div className="stop__content">
                      <h4 className="heading--h4 text-primary">
                        Sorry, you can&apos;t progress with your online order.
                      </h4>
                      <p className="p-small">
                        We need to speak to you in order to assess your needs and make sure
                        we&apos;re offering you the right products. Please speak to our friendly
                        advisors on 020 4586 8868 to continue.
                      </p>
                      <p className="p-small">
                        If you have a health care or medical alarm, you will need to check with your
                        alarm provider that it will work with your internet connection.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
            <CheckoutFormRow
              key="confirmResponsibility"
              name="confirmResponsibility"
              renderLabel={() => (
                <Heading level={3}>
                  {confirmResponsibilityStatement ||
                    'I confirm I will be responsible for bill payments for the new services provided by Hey!Broadband.'}
                  *
                </Heading>
              )}
              formData={customerAdditional}
              validators={additionalFieldValidators}
              onChange={updateAdditionalFormData}
              type="boolean"
              className="booleanInput smallBooleanInput"
              required={true}
            />
            {customerAdditional.confirmResponsibility !== undefined &&
            !customerAdditional.confirmResponsibility ? (
              <span className="ml-6 font-bold error">
                You must be responsible for for bill payments if you wish to proceed.
              </span>
            ) : null}
          </StyledAdditionalForm>
        )}
        {(isEligible || isRegisterInterest) && (
          <StyledCheckoutCheckboxContainer>
            <StyledCheckoutCheckbox>
              <CheckBox
                value={customer.accept_marketing}
                label={renderParagraphs({
                  body: `<span class="${
                    !isRegisterInterest ? '' : 'text-white'
                  }">${smallCopy}</span>`,
                  hasMarkdown: true,
                })}
                name="accept_marketing"
                id="accept_marketing"
                checked={customer.accept_marketing}
                onClick={() => updateFormData('accept_marketing', !customer.accept_marketing)}
              />
            </StyledCheckoutCheckbox>

            {isRegisterInterest && (
              <StyledCheckoutCheckbox>
                <CheckBox
                  value="Checkbox"
                  label={renderParagraphs({
                    body: `<span class="${
                      !isRegisterInterest ? '' : 'text-white'
                    }">I agree to the [Terms & Conditions](/legal)</span>`,
                    hasMarkdown: true,
                  })}
                  name="accept_terms"
                  id="accept_terms"
                  checked={customer.accept_terms}
                  onClick={(value) => updateFormData('accept_terms', value)}
                />
              </StyledCheckoutCheckbox>
            )}
          </StyledCheckoutCheckboxContainer>
        )}
        {isPreorder && (
          <StyledPreorderNote>
            You&apos;ll not be charged as this is a pre-order.
          </StyledPreorderNote>
        )}
        {!isSignup && !isPreorder && (
          <StyledButtonRow>
            <Button buttonType={ButtonType.ALTERNATE} type="submit" disabled={!isValid}>
              {registerInterest ? 'Register your interest' : 'Submit'}
            </Button>
          </StyledButtonRow>
        )}
        {isSignup && (
          <StyledCheckoutFormRow>
            <StyledIconParagraph>
              <Icon xs icon={LockIcon} />
              <strong>{secureShoppingTitle}</strong>
            </StyledIconParagraph>

            {renderParagraphs({ body: secureShoppingDescription || '', hasMarkdown: true })}
          </StyledCheckoutFormRow>
        )}
        {recaptchaErrored && (
          <StyledErrorBar>Recaptcha failed. Please refresh and try again</StyledErrorBar>
        )}

        <div className="my-4 md:block flex justify-center" id="recaptcha-container"></div>

        {(!isValid || !canProceed) && (
          <StyledErrorBar>
            Please recheck the fields and tick boxes above to ensure there are no errors to proceed.
          </StyledErrorBar>
        )}
      </form>
    </div>
  );
};
