import StyledMiniBasketPaymentSchedule from '@components/Organisms/MiniBasket/styled/StyledMiniBasketPaymentSchedule';
import { MonthlyPaymentT } from '@lib/types';
import { getFormattedPrice } from '@lib/utils';
import React, { useMemo } from 'react';

const MiniBasketPaymentSchedule = ({
  monthlyPayment,
  fullWidth = false,
}: {
  monthlyPayment: MonthlyPaymentT;
  fullWidth?: boolean;
}) => {
  const { details, total_free_months } = monthlyPayment;

  const schedule = useMemo(() => {
    let totalFirstCharge = 0;

    details.forEach((detail) => {
      totalFirstCharge += detail.first_charge;
    });

    const groupedMonths: { month: number; span: number; price: number }[] = [];
    let numOfMonthsToShow = total_free_months + 1;
    // Display at least 2 months when there is a first charge
    if (totalFirstCharge !== 0 && numOfMonthsToShow < 2) {
      numOfMonthsToShow = 2;
    }

    let monthNum = 1;
    while (monthNum <= numOfMonthsToShow) {
      let monthlyPrice = monthNum === 1 ? totalFirstCharge : 0;

      details.forEach((detail) => {
        if (detail.free_months < monthNum) {
          monthlyPrice += detail.monthly_price;
        }
      });

      if (groupedMonths.length > 0) {
        const lastGroupedMonth = groupedMonths[groupedMonths.length - 1];
        if (lastGroupedMonth.price === monthlyPrice) {
          lastGroupedMonth.span++;
        } else {
          groupedMonths.push({ month: monthNum, span: 1, price: monthlyPrice });
        }
      } else {
        groupedMonths.push({ month: monthNum, span: 1, price: monthlyPrice });
      }

      monthNum++;
    }

    return groupedMonths;
  }, [details, total_free_months]);

  return (
    <StyledMiniBasketPaymentSchedule fullWidth={fullWidth}>
      <strong>Payment schedule</strong>
      <ul>
        {schedule.map(({ month, span, price }, scheduleIndex) => {
          return (
            <li key={month}>
              <span>{`Month ${month}${span > 1 ? `-${month + span - 1}` : ''}${
                schedule.length === scheduleIndex + 1 ? ' onwards' : ''
              }`}</span>
              <span className="divider" />
              <span>{`£${getFormattedPrice(price)}`}</span>
            </li>
          );
        })}
      </ul>
    </StyledMiniBasketPaymentSchedule>
  );
};

export default MiniBasketPaymentSchedule;
