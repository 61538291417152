import styled from 'styled-components';

export default styled.div<{ isSelected?: boolean }>`
  position: absolute;
  top: ${({ isSelected }) => (isSelected ? '-32px' : '-17px')};
  height: 35px;
  background: #2c2cde;
  border-radius: 0.5rem;
  padding: 0.4rem 0.8rem;
  color: white;
  font-size: 0.875rem;
  font-weight: bold;
  font-family: var(--fonts-family-secondary);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
`;
